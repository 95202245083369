import moment from 'moment';

const utils = {};

utils.validateCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999')
    return false;
  // Valida 1o digito	
  let add = 0;
  for (let i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  if (rev !== parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito	
  add = 0;
  for (let i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  return rev === parseInt(cpf.charAt(10));
};

utils.validateEmptyDateTime = (person, dateFieldNames) => {
  const changedPerson = structuredClone(person);

  dateFieldNames.forEach(fieldName => {
    if (changedPerson[fieldName] === '')
      changedPerson[fieldName] = null;

    if (changedPerson[fieldName] !== null)
      changedPerson[fieldName] = moment(changedPerson[fieldName]).format('YYYY-MM-DD');
  });

  return changedPerson;
};

utils.validadeValue = value  =>{
  return value !== undefined && value !== 0;
};

utils.validaCopy = (p, cP) => {
  let st = false;

  let person = {...p};
  let copyPerson ={...cP};
  
  if(person.personCompl?.length){
    const compl = [...person.personCompl];
    person = {...person, personCompl : compl.sort((a, b) => a.columnCompl > b.columnCompl ? 1 : -1)}
  }

  if(copyPerson.personCompl?.length){
    const complCopy = [...copyPerson.personCompl];
    copyPerson = {...copyPerson, personCompl : complCopy.sort((a, b) => a.columnCompl > b.columnCompl ? 1 : -1) }
  }

  Object.keys(person).forEach(key => {
    if (!(person[key] instanceof Array)) {
      if (person[key] != copyPerson[key] && copyPerson[key] != undefined && key != 'isLoad') {
        if (!isNaN(new Date(copyPerson[key]).getDate())) {
          if (moment(copyPerson[key]).format('YYYY-MM-DD') != person[key])
            st = true;
        } else {
          st = true;
        }
      }
    } else {

      if ((copyPerson[key] != undefined && person[key].length != copyPerson[key].length) || (copyPerson[key] == undefined && person[key].length)
      || ((!person[key] || person[key].length == 0) && copyPerson[key]?.length)) {
        st = true;
      }
      else {
        person[key].forEach((element, idx) => {
          Object.keys(person[key][idx]).forEach(key2 => {
            if (person[key][idx][key2] != copyPerson[key][idx][key2] && copyPerson[key][idx][key2] != undefined) {
              if (!isNaN(new Date(copyPerson[key][idx][key2]).getDate())) {
                if (moment(copyPerson[key][idx][key2]).format('YYYY-MM-DD') != person[key][idx][key2])
                  if(utils.validadeValue(person[key][idx][key2]) && utils.validadeValue(copyPerson[key][idx][key2])){
                   st = true;
                  }
              } else {
                if ((person[key][idx][key2] instanceof Array)) {
                  person[key][idx][key2].forEach((element, idx2) => {
                    Object.keys(person[key][idx][key2][idx2]).forEach(key3 => {
                      if (person[key][idx][key2][idx2][key3] != copyPerson[key][idx][key2][idx2][key3] && copyPerson[key][idx][key2][idx2][key3] != undefined) {
                            st = true;
                      }
                    });

                  });
                }
                else{
                  st = true;
                }

              }
            }

          });

        });
      }

    }
  });

  return st;
}

utils.validatePIS = (pis) => {
  pis = pis.replace(/[^\d]+/g, '');
  if (pis === '') return false;
  
  if (pis.length !== 11) 
    return false;

  const peso = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let soma = 0;
  for (let i = 0; i < peso.length; i++) 
    soma += peso[i] * parseInt(pis[i], 10);

  const resto = soma % 11;

  const digitoVerificador = resto < 2 ? 0 : 11 - resto;
  return digitoVerificador === parseInt(pis[10], 10);
    
};

export default utils;